const axios = require('axios')
const env = window.Cypress ? window.Cypress.env() : process.env

export default class SchedulingServiceClient {
  constructor () {
    const headers = {
      'x-api-key': env.VUE_APP_SCHEDULING_SERVICE_API_KEY
    }
    this.client = axios.create({
      baseURL: env.VUE_APP_SCHEDULING_SERVICE_DOMAIN,
      headers
    })
  }

  getSchedulingVersion (practiceUuid) {
    return this.client.get(`/v2/practices/${practiceUuid}/feature-settings/scheduling.book-online.widget.type`)
  }
}
