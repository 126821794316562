import axios from 'axios'
import SchedulingServiceClient from '@/clients/SchedulingServiceClient'
import {
  WIDGET_VERSIONS,
  FALLBACK_WIDGET_VERSION,
  MODE_PARAM_MAPPING,
  STORAGE_CONFIG_ITEM_NAME,
  ORIGIN_CARE_CONNECT,
  ORIGIN_PRACTICE_KAREO,
  PG_PRACTICE_UUID_FORCE_USE_PO
} from '@loader-src/constants'
const env = window.Cypress ? window.Cypress.env() : process.env
class SchedulingWrapperWidgetLoader {
  constructor (config = {}) {
    this.baseConfig = config
    this.widgetVersion = FALLBACK_WIDGET_VERSION
    this.widgetURL = this.getWidgetURL(this.widgetVersion)
    this.widgetOrigin = this.getWidgetURL(this.widgetVersion)
    this.rawWidgetHtml = ''
    this.processedWidgetHtml = ''
    this.configForCurrentWidget = this.createConfigWidgetSpecificConfig(this.baseConfig, this.widgetVersion)
    this.runWidget()
  }

  async runWidget () {
    this.widgetVersion = await this.checkWidgetVersion(this.baseConfig.practiceUuid)
    this.widgetURL = this.getWidgetURL(this.widgetVersion)
    this.widgetOrigin = (new URL(this.widgetURL)).origin
    this.rawWidgetHtml = await this.loadWidgetCodeByUrl(this.widgetURL)
    this.processedWidgetHtml = this.processedWidgetHTML(this.rawWidgetHtml, this.widgetOrigin)
    this.configForCurrentWidget = this.createConfigWidgetSpecificConfig(this.baseConfig, this.widgetVersion)
    this.passConfigToWidget(this.configForCurrentWidget)
    this.showWidget(this.processedWidgetHtml)
  }

  async checkWidgetVersion (practiceUuid) {
    // if practiceKey exists && it's for PO - use unified scheduling widget
    if (Boolean(this.baseConfig.practiceKey) && String(this.baseConfig.practiceKey).startsWith('k')) {
      return WIDGET_VERSIONS.BOOK_ONLINE_UNIFIED
    }
    const schedulingService = new SchedulingServiceClient()
    const value = await schedulingService.getSchedulingVersion(practiceUuid).then((response) => response.data.feature_setting_value)
    return WIDGET_VERSIONS[value] ?? FALLBACK_WIDGET_VERSION
  }

  getWidgetURL (version) {
    switch (version) {
      case WIDGET_VERSIONS.BOOK_ONLINE_UNIFIED:
        return env.VUE_APP_UNIFIED_SCHEDULING_URL
      case WIDGET_VERSIONS.BOOK_ONLINE_V4:
        return env.VUE_APP_BOOK_ONLINE_V4_URL
      case WIDGET_VERSIONS.APPOINTMENT_REQUEST:
        return env.VUE_APP_BOOK_ONLINE_V3_URL
      case WIDGET_VERSIONS.BOOK_ONLINE_V2:
        return env.VUE_APP_BOOK_ONLINE_V2_URL
      default:
        return env.VUE_APP_BOOK_ONLINE_V3_URL
    }
  }

  async loadWidgetCodeByUrl (url) {
    return await axios.get(url).then(response => {
      return response.data
    }).catch((e) => {
      return ''
    })
  }

  processedWidgetHTML (html, origin) {
    const doc = document.implementation.createHTMLDocument('')
    doc.open()
    doc.write(html)
    doc.close()
    const baseTag = doc.createElement('base')
    baseTag.href = origin
    doc.head.appendChild(baseTag)
    const allScriptAndStyleNodes = doc.documentElement.querySelectorAll('link, script')
    allScriptAndStyleNodes.forEach((item) => {
      const propName = item.src ? 'src' : 'href'
      if (item[propName]) {
        const a = doc.createElement('a')
        a.href = item[propName]
        let pathName = a.href.indexOf('http') < 0 ? a.href : a.pathname
        pathName = pathName.indexOf('/') !== 0 ? `/${pathName}` : pathName
        item[propName] = `${origin}${pathName}`
      }
    })
    return `${doc.head.outerHTML}${doc.body.outerHTML}`
  }

  createConfigWidgetSpecificConfig (baseConf = {}, version = FALLBACK_WIDGET_VERSION) {
    // all of this parameters are common for all widgets
    const specificConfig = {
      practiceId: (baseConf.practiceId ?? null),
      practiceUuid: (baseConf.practiceUuid ?? null),
      originHost: (baseConf.originHost ?? ''),
      showCloseButton: baseConf.showCloseButton ? 1 : 0,
      gaId: (baseConf.gaId ?? null)
    }

    // add origin only if exist
    if (baseConf.origin) {
      specificConfig.origin = baseConf.origin
    }

    if (WIDGET_VERSIONS.BOOK_ONLINE_UNIFIED === version) {
      specificConfig.practiceKey = baseConf.practiceKey
      specificConfig.showCloseButton = (specificConfig?.origin === ORIGIN_CARE_CONNECT || specificConfig?.origin === ORIGIN_PRACTICE_KAREO) ? 0 : 1 // Do not display widget close button on CareConnect & Practice.kareo4
    }

    if (MODE_PARAM_MAPPING[version]) {
      specificConfig.mode = MODE_PARAM_MAPPING[version]
    }
    if ([WIDGET_VERSIONS.BOOK_ONLINE_V4, WIDGET_VERSIONS.BOOK_ONLINE_UNIFIED].includes(version)) {
      specificConfig.useWidgetManager = !baseConf.notWidgetManagerUsage
      if (baseConf.locationId) {
        specificConfig.locationId = baseConf.locationId
      }
      if (baseConf.providerId) {
        specificConfig.providerId = baseConf.providerId
      }
      if (baseConf.appointmentTypeId) {
        specificConfig.appointmentTypeId = baseConf.appointmentTypeId
      }

      // @TODO: remove after https://kareodev.atlassian.net/browse/SCHED-2562 is done.
      if (specificConfig.practiceUuid in PG_PRACTICE_UUID_FORCE_USE_PO) {
        specificConfig.practiceKey = PG_PRACTICE_UUID_FORCE_USE_PO[specificConfig.practiceUuid]
        // We cannot translate these so we drop them
        specificConfig.providerId = null
        specificConfig.locationId = null
        specificConfig.appointmentTypeId = null
      }
    }

    return specificConfig
  }

  passConfigToWidget (config = {}) {
    localStorage.setItem(STORAGE_CONFIG_ITEM_NAME, JSON.stringify(config))
  }

  showWidget (html) {
    if (!html) {
      return
    }
    document.open()
    document.write(html)
    document.close()
  }
}

let instanse
export default (config) => {
  if (!instanse) {
    instanse = new SchedulingWrapperWidgetLoader(config)
    // later can be used from widgets
    window.SCHEDULING_WRAPPER_WIDGET_LOADER = instanse
  }
  return instanse
}
